import React from "react"
import { graphql } from "gatsby"

import { Layout, SEO } from "../components/"
import { rhythm, scale } from "../utils/typography"

const ProjectTemplate = ({ data, location }) => {
  const project = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={project.frontmatter.title}
        description={project.frontmatter.description || project.excerpt}
      />
      <article>
        <header>
          <h2
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {project.frontmatter.title}
          </h2>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {project.frontmatter.site && (
              <a href={project.frontmatter.site}>Site</a>
            )}
            {project.frontmatter.site && project.frontmatter.github && "  |  "}
            {project.frontmatter.github && (
              <a href={project.frontmatter.github}>GitHub</a>
            )}
          </p>
        </header>
        {/* <section>{projectHtml}</section> */}
        <section
          id="project-info"
          dangerouslySetInnerHTML={{ __html: project.html }}
        />
      </article>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        site
        github
        video
      }
    }
  }
`
